import { Elm } from './Main.elm';


// Navbar burger support
document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target?.classList.toggle('is-active');

      });
    });
  }

});

const basePath = new URL(document.baseURI).pathname;

const app = Elm.Main.init({
  node: document.querySelector('main'),
  flags: {
    oidcState: checkForState(),
    basePath: basePath 
  }
});

interface StateControlMessage {
  key: string
  value: object
}

app.ports.storeState.subscribe((state: StateControlMessage) => {
  cleanupState();
  var stateJson = JSON.stringify(state.value);
  localStorage.setItem(state.key, stateJson);
  localStorage.setItem("stateKey", state.key);
  app.ports.stateStored.send(null);
});

function checkForState(): string | null {
  return localStorage.getItem("stateKey");
}

app.ports.readState.subscribe((key: string) => {
  const state = localStorage.getItem(key);
  if (state != null) {
    app.ports.stateRead.send(JSON.parse(state));
  } else {
    console.error("State not found");
  }
});

function cleanupState() {
  const st = localStorage.getItem("stateKey");
  if (st) {
    localStorage.removeItem(st);
    localStorage.removeItem("stateKey");
  }
}
