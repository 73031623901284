module Main exposing (..)

import Browser exposing (Document)
import Browser.Navigation as Nav
import Dict exposing (Dict)
import Either exposing (Either(..))
import Furniture as F
import Html exposing (Html, div)
import Html.Attributes exposing (class, href)
import Http
import Internal.OIDC as OIDC exposing (resolveAPIBase)
import Internal.Products as P exposing (BusinessAccountPlan, Product, Products, SubscriptionResult, loadSubscription)
import Json.Decode exposing (Value, at, decodeValue, string)
import MatrixId.API exposing (MatrixIdError)
import MatrixId.EnvStartup as Env
import MatrixId.SharedViews as SharedViews
import MatrixId.Types exposing (Origin, URLBase, genOrigin)
import Maybe.Extra exposing (isJust)
import OAuth
import OAuth.AuthorizationCode as OAuth
import Page.Dashboard as Dashboard
import Page.Home as Home
import Page.Individuals as I
import Page.Login as Login
import Page.SiteOwnerHome as SHome
import Page.Subscription as Subscr
import Route exposing (Route(..), SubscriptionAction(..))
import Time exposing (Zone, here)
import Time.Format.Config exposing (Config)
import Time.Format.Config.Configs exposing (getConfig)
import Url
import Url.Builder as Url


type alias Model =
    { navKey : Nav.Key
    , page : Page
    , zone : Zone
    , timeConfig : Config
    , accessToken : Maybe OAuth.Token
    , products : Maybe Products
    , origin : Origin
    , apiBase : URLBase
    }


type Page
    = Dashboard OAuth.Token Dashboard.Model
    | Home Home.Model
    | SiteOwnerHome SHome.Model
    | Individuals I.Model
    | OAuthError OAuth.AuthorizationError
    | InProgress String
    | ReadingState Url.Url
    | Subscription Subscr.Model
    | NotFound
    | LoginPage Login.Model
    | Error String


type Msg
    = Unauthenticated
    | LinkClicked Browser.UrlRequest
    | UrlChanged Url.Url
    | HandleOAuthToken Url.Url String (Result Http.Error OIDC.OIDCAuthSuccess)
    | StartOAuthProcess OIDC.State Url.Url
    | DashboardMsg Dashboard.Msg
    | IndividualsMsg I.Msg
    | HomeMsg Home.Msg
    | NoState
    | SubscriptionMsg Subscr.Msg
    | SubscriptionChecked OAuth.Token (Maybe P.BusinessAccountUsage -> ( Model, Cmd Msg )) (Result MatrixIdError P.SubscriptionResult)
    | ProductsLoaded (Dict String Product -> ( Model, Cmd Msg )) (Result Http.Error Products)
    | BurgerStatus
    | LoginMsg Login.Msg


main : Program Value (Env.SuperModel Model) (Env.SuperMsg Msg Value Zone)
main =
    Browser.application
        { init = initWithZone
        , update = postUpdate
        , view = postView
        , subscriptions = postSubscriptions
        , onUrlRequest = Env.Running << LinkClicked
        , onUrlChange = Env.Running << UrlChanged
        }


initWithZone : Value -> Url.Url -> Nav.Key -> ( Env.SuperModel Model, Cmd (Env.SuperMsg Msg Value Zone) )
initWithZone v u k =
    Env.delayedInit v u k here


postUpdate : Env.SuperMsg Msg Value Zone -> Env.SuperModel Model -> ( Env.SuperModel Model, Cmd (Env.SuperMsg Msg Value Zone) )
postUpdate msg model =
    Env.superUpdate msg model init update


postView : Env.SuperModel Model -> Document (Env.SuperMsg Msg Value Zone)
postView model =
    let
        centered html =
            div [ class "container has-text-centered" ]
                [ div [ class "columns is-multiline is-centered is-vcentered" ]
                    [ div [ class "column has-text-centered" ] [ html ] ]
                ]
    in
    Env.superView "MatrixId"
        model
        (\_ ->
            F.mainView BurgerStatus
                False
                False
                { content =
                    [ centered <| SharedViews.progressViewRoute "Starting MatrixId..." ( Route.routeToString Route.Home, "Home" ) ]
                , styleModifier = Nothing
                }
        )
        (\inModel ->
            contentForModel inModel
        )
        (F.mainView BurgerStatus
            False
            False
            { content = [ centered <| SharedViews.renderStop "Couldn't start the application" "MatrixId For Business" (href "/") ]
            , styleModifier = Nothing
            }
        )


postSubscriptions : Env.SuperModel Model -> Sub (Env.SuperMsg Msg iv res)
postSubscriptions model =
    Env.superSubscriptions model subscriptions


oidcConfig : Nav.Key -> Zone -> OIDC.State -> Origin -> Url.Url -> OIDC.OIDCResponseParser Model Msg
oidcConfig nk zone state origin url =
    { navKey = nk
    , unauthenticatedHome = "/"
    , errorModel =
        \err ->
            { page = OAuthError err
            , navKey = nk
            , zone = zone
            , timeConfig = getConfig "en_AU"
            , accessToken = Nothing
            , products = Nothing
            , origin = origin
            , apiBase = resolveAPIBase origin
            }
    , progressModel =
        { page = InProgress "Logging you in..."
        , navKey = nk
        , zone = zone
        , timeConfig = getConfig "en_AU"
        , accessToken = Nothing
        , products = Nothing
        , origin = origin
        , apiBase = resolveAPIBase origin
        }
    , tokenHandler = HandleOAuthToken url state.nextURL
    , tokenAuthentication = OIDC.tokenCreds origin
    , state = state
    }


init : Value -> Url.Url -> Nav.Key -> Zone -> ( Model, Cmd Msg )
init val url key zone =
    let
        state =
            case decodeValue (at [ "oidcState" ] string) val of
                Ok str ->
                    Just str

                Err _ ->
                    Nothing

        basePath =
            case decodeValue (at [ "basePath" ] string) val of
                Ok str ->
                    Just str

                Err _ ->
                    Nothing

        origin =
            genOrigin url basePath

        genModel page =
            { navKey = key
            , page = page
            , zone = zone
            , timeConfig = getConfig "en_AU"
            , accessToken = Nothing
            , products = Nothing
            , origin = origin
            , apiBase = resolveAPIBase origin
            }
    in
    case ( Route.fromUrl origin url, state ) of
        ( Just Route.AuthComplete, Just st ) ->
            ( genModel (ReadingState url), OIDC.readState st )

        ( Just Route.SiteOwners, _ ) ->
            changeFromRoute Route.SiteOwners (genModel (InProgress "Loading products...")) True

        ( Just (Route.Subscription sba), _ ) ->
            changeFromRoute (Route.Subscription sba) (genModel (InProgress "Processing subscription...")) True

        ( Just (Route.Dashboard mi), _ ) ->
            changeFromRoute (Route.Dashboard mi) (genModel (InProgress "Loading your dashboard...")) True

        ( Just _, _ ) ->
            changeFromRoute Route.Home (genModel (InProgress "Loading products...")) True

        ( Nothing, _ ) ->
            ( genModel NotFound
            , Cmd.none
            )


subscriptions : Model -> Sub Msg
subscriptions mdl =
    case mdl.page of
        ReadingState url ->
            OIDC.stateRead <|
                \val ->
                    case decodeValue OIDC.parseState val of
                        Ok state ->
                            StartOAuthProcess state url

                        Err _ ->
                            NoState

        Individuals hm ->
            Sub.map IndividualsMsg (I.subscriptions hm)

        LoginPage lm ->
            Sub.map LoginMsg (Login.subscriptions lm)

        _ ->
            Sub.none


contentForModel : Model -> List (Html Msg)
contentForModel mdl =
    let
        reloadHome =
            Route.href Route.Reload
    in
    F.mainView BurgerStatus False (isJust mdl.accessToken) <|
        case mdl.page of
            Dashboard at homeModel ->
                { content =
                    List.map (Html.map DashboardMsg)
                        (Dashboard.view
                            { zone = mdl.zone
                            , accessToken = at
                            , timeConfig = mdl.timeConfig
                            , homePage = Route.routeToString Route.Home
                            , origin = mdl.origin
                            }
                            homeModel
                        )
                , styleModifier = Just "is-align-items-flex-start"
                }

            InProgress txt ->
                { content = [ F.mainBodyWrapper [ SharedViews.progressViewRoute txt ( Route.routeToString Route.Home, "Home" ) ] ], styleModifier = Nothing }

            OAuthError err ->
                { content =
                    [ F.mainBodyWrapper
                        [ SharedViews.renderStop
                            ("Unfortunately we were not able to identify you. Your MatrixId attempt failed with "
                                ++ OAuth.errorCodeToString err.error
                            )
                            "MatrixId For Business"
                            reloadHome
                        ]
                    ]
                , styleModifier = Nothing
                }

            Home m ->
                { content = Home.view mdl.origin m
                , styleModifier = Nothing
                }

            SiteOwnerHome m ->
                { content = SHome.view m
                , styleModifier = Nothing
                }

            Individuals m ->
                { content = List.map (Html.map IndividualsMsg) (I.view mdl.origin m)
                , styleModifier = Nothing
                }

            ReadingState _ ->
                { content = [ F.mainBodyWrapper [ SharedViews.progressViewRoute "Loading some info..." ( Route.routeToString Route.Home, "Home" ) ] ]
                , styleModifier = Nothing
                }

            Subscription m ->
                { content = List.map (Html.map SubscriptionMsg) [ Subscr.view m ]
                , styleModifier = Nothing
                }

            NotFound ->
                { content = [ F.mainBodyWrapper [ SharedViews.renderStop "We weren't able to find that." "MatrixId For Business" reloadHome ] ]
                , styleModifier = Nothing
                }

            Error e ->
                { content = [ F.mainBodyWrapper [ SharedViews.renderStop e "MatrixId For Business" reloadHome ] ]
                , styleModifier = Nothing
                }

            LoginPage _ ->
                { content = [ F.mainBodyWrapper [ SharedViews.progressViewRoute "Logging you in..." ( Route.routeToString Route.Home, "Home" ) ] ]
                , styleModifier = Nothing
                }


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model.page ) of
        ( Unauthenticated, _ ) ->
            ( model, Cmd.none )

        ( LinkClicked urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    ( model
                    , Nav.pushUrl model.navKey (Url.toString url)
                    )

                Browser.External href ->
                    ( model
                    , Nav.load href
                    )

        ( UrlChanged url, _ ) ->
            changeFromURL url model

        ( StartOAuthProcess state url, _ ) ->
            OIDC.parseOIDCResponse url state.codeVerifier (oidcConfig model.navKey model.zone state model.origin url)

        ( HandleOAuthToken url nextUrl res, _ ) ->
            case res of
                Ok at ->
                    changeFromURL { url | path = nextUrl } { model | accessToken = Just at.token }
                        |> (\( mdl, c2 ) -> ( mdl, Cmd.batch [ c2, SharedViews.clearUrl model.origin model.navKey nextUrl ] ))

                Err _ ->
                    ( { model | page = Error "We we unable to authenticate you. The error was " }, Cmd.none )

        ( NoState, _ ) ->
            Home.init Nothing
                |> (\( m, c ) ->
                        ( { model | page = Home m }, Cmd.map HomeMsg c )
                   )

        ( DashboardMsg hm, Dashboard at hmod ) ->
            let
                subInitWithProds prods =
                    Dashboard.update model.apiBase at prods hm hmod |> (\( m, c ) -> ( { model | page = Dashboard at m, products = Just prods }, Cmd.map DashboardMsg c ))
            in
            nextNeedingProducts model
                (\_ prods -> subInitWithProds prods)
                (\_ ->
                    Login.init "dashboard"
                        |> (\( m, c ) ->
                                ( { model | page = LoginPage m }, Cmd.map LoginMsg c )
                           )
                )

        ( IndividualsMsg hm, Individuals _ ) ->
            I.update model.origin hm |> (\( m, c ) -> ( { model | page = Individuals m }, Cmd.map IndividualsMsg c ))

        ( SubscriptionMsg hm, Subscription hmod ) ->
            case model.accessToken of
                Just at ->
                    Subscr.update model.apiBase at hm hmod |> (\( m, c ) -> ( { model | page = Subscription m }, Cmd.map SubscriptionMsg c ))

                Nothing ->
                    ( { model | page = Error "Unable to process your subscription status" }, Cmd.none )

        ( ProductsLoaded next res, _ ) ->
            case res of
                Ok pl ->
                    next pl

                Err _ ->
                    ( { model | page = Error "Unable to load product list!" }, Cmd.none )

        ( SubscriptionChecked _ msgmod res, _ ) ->
            processSubscriptionResult res model msgmod

        ( LoginMsg hm, LoginPage hmod ) ->
            Login.update model.origin hm hmod |> (\( m, c ) -> ( { model | page = LoginPage m }, Cmd.map LoginMsg c ))

        ( HomeMsg hm, Home _ ) ->
            Home.update hm |> (\( m, c ) -> ( { model | page = Home m }, Cmd.map HomeMsg c ))

        _ ->
            ( model, Cmd.none )


changeFromURL : Url.Url -> Model -> ( Model, Cmd Msg )
changeFromURL url model =
    case Route.fromUrl model.origin url of
        Just rt ->
            changeFromRoute rt model False

        Nothing ->
            ( { model | page = NotFound }, Cmd.none )


changeFromRoute : Route -> Model -> Bool -> ( Model, Cmd Msg )
changeFromRoute route model pushURL =
    let
        routeCmds c rt msg =
            Cmd.batch
                (Cmd.map msg c
                    :: (if pushURL then
                            [ Nav.pushUrl model.navKey (Route.routeToString rt) ]

                        else
                            []
                       )
                )

        homeInit _ =
            Home.init model.accessToken
                |> (\( m, c ) ->
                        ( { model | page = Home m }, Cmd.map HomeMsg c )
                   )
    in
    case route of
        Route.Reload ->
            ( model, Nav.reload )

        Route.Logout ->
            Home.init Nothing
                |> (\( m, c ) ->
                        ( { model | page = Home m, accessToken = Nothing }, Cmd.map HomeMsg c )
                   )

        Route.Dashboard mrt ->
            case model.accessToken of
                Just at ->
                    let
                        subInitWithProds _ prods =
                            Dashboard.init model.apiBase at prods mrt
                                |> (\( m, c ) ->
                                        ( { model | page = Dashboard at m, accessToken = Just at, products = Just prods }
                                        , routeCmds c (Route.Dashboard mrt) DashboardMsg
                                        )
                                   )
                    in
                    nextNeedingProducts model subInitWithProds homeInit

                Nothing ->
                    Login.init "dashboard"
                        |> (\( m, c ) ->
                                ( { model | page = LoginPage m }
                                , routeCmds c (Route.Dashboard Nothing) LoginMsg
                                )
                           )

        Route.Home ->
            Home.init model.accessToken
                |> (\( m, c ) ->
                        ( { model | page = Home m }, routeCmds (Cmd.map HomeMsg c) Route.Home identity )
                   )

        Route.SiteOwners ->
            let
                withSubcr at prods =
                    ( { model | page = InProgress "Checking your existing subscription", products = Just prods }
                    , loadSubscription model.apiBase at prods (SubscriptionChecked at (shmmod model prods))
                    )
            in
            nextNeedingProducts model withSubcr (\prods -> shmmod model prods Nothing)

        Route.Individuals ->
            ( { model | page = Individuals (Maybe.withDefault I.Unauth (Maybe.map (\_ -> I.Authenticated) model.accessToken)) }, Cmd.none )

        Route.Subscription subrAction ->
            let
                subInitWithProds at prods =
                    ( { model | page = InProgress "Checking your existing subscription", products = Just prods }
                    , loadSubscription model.apiBase at prods (SubscriptionChecked at (subcrStart (Just at) prods subrAction model))
                    )
            in
            nextNeedingProducts model subInitWithProds (\prods -> subcrStart Nothing prods subrAction model Nothing)

        Route.NotFound ->
            ( { model | page = NotFound }, Cmd.none )

        Route.Login rt ->
            Login.init (Route.routeToString rt)
                |> (\( m, c ) ->
                        ( { model | page = LoginPage m }, routeCmds c (Route.Login rt) LoginMsg )
                   )

        _ ->
            ( { model | page = NotFound }, Cmd.none )


subcrStart :
    Maybe OAuth.Token
    -> Products
    -> Route.SubscriptionAction
    -> { d | page : Page }
    -> Maybe { a | bap : BusinessAccountPlan }
    -> ( { d | page : Page }, Cmd Msg )
subcrStart at prods prod model baum =
    Subscr.init at prods prod (Maybe.map .bap baum)
        |> (\( m, c ) ->
                ( { model | page = Subscription m }, Cmd.map SubscriptionMsg c )
           )


shmmod : Model -> Products -> Maybe { b | bap : BusinessAccountPlan } -> ( Model, Cmd Msg )
shmmod model prds baum =
    SHome.init model.accessToken prds (Maybe.map .bap baum)
        |> (\( m, c ) ->
                ( { model | page = SiteOwnerHome m }, c )
           )


processSubscriptionResult :
    Result error SubscriptionResult
    -> Model
    -> (Maybe P.BusinessAccountUsage -> ( Model, Cmd msg ))
    -> ( Model, Cmd msg )
processSubscriptionResult res model msgmod =
    case res of
        Ok (P.SubscriptionFound bau) ->
            msgmod (Just bau)

        Ok P.NoSubscription ->
            msgmod Nothing

        Err _ ->
            ( { model | page = Error "Unable to check on your subscription status!" }, Cmd.none )


nextNeedingProducts :
    Model
    -> (OAuth.Token -> Dict String Product -> ( Model, Cmd Msg ))
    -> (Dict String Product -> ( Model, Cmd Msg ))
    -> ( Model, Cmd Msg )
nextNeedingProducts model subInitWithProds prodsUnauthInit =
    case ( model.products, model.accessToken ) of
        ( Just prods, Just at ) ->
            subInitWithProds at prods

        ( Nothing, Just at ) ->
            ( { model | page = InProgress "Loading products..." }, P.getProducts model.apiBase (ProductsLoaded (subInitWithProds at)) )

        ( Just prods, Nothing ) ->
            prodsUnauthInit prods

        ( Nothing, Nothing ) ->
            ( { model | page = InProgress "Loading products..." }, P.getProducts model.apiBase (ProductsLoaded prodsUnauthInit) )
